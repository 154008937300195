const paintingsOutput = `
<div class="paintings-grid">
  <img src="image1.webp" alt="Painting 1">
  <img src="image2.webp" alt="Painting 2">
  <img src="image3.webp" alt="Painting 3">
  <img src="image4.webp" alt="Painting 4">
  <img src="image5.webp" alt="Painting 5">
  <img src="image6.webp" alt="Painting 6">
  <img src="image7.webp" alt="Painting 7">
  <img src="image8.webp" alt="Painting 8">
  <img src="image9.webp" alt="Painting 9">
  <img src="image10.webp" alt="Painting 10">
  <img src="image11.webp" alt="Painting 11">
  <img src="image12.webp" alt="Painting 12">
  <img src="image13.webp" alt="Painting 13">
  <img src="image14.webp" alt="Painting 14">
  <img src="image15.webp" alt="Painting 15">
  <img src="image16.webp" alt="Painting 16">
  <img src="image17.webp" alt="Painting 17">
  <img src="image18.webp" alt="Painting 18">
  <img src="image19.webp" alt="Painting 19">
  <img src="image20.webp" alt="Painting 20">
  <img src="image21.webp" alt="Painting 21">
  <img src="image22.webp" alt="Painting 22">
  <img src="image23.webp" alt="Painting 23">
  <img src="image24.webp" alt="Painting 24">
  <img src="image25.webp" alt="Painting 25">
  <img src="image26.webp" alt="Painting 26">
  <img src="image27.webp" alt="Painting 27">
</div>
<p><strong>My Creative outlet</strong>: Artwork is hand-painted on an iPad using the Procreate app, an awesome painting app. <a href="https://www.deviantart.com/jamesgodwin" target="_blank">view all paintings</a></p>

<hr>
  <p class="commands-text">Available commands: - help - about - now - apps - books - portfolio - paintings  - uiux - contact - code - taoism<br />themes (default, snow, mist, zen, tao, mountain)  - clear</p>
 
`;
export default paintingsOutput;