const helpOutput = `
Available commands:
- help
- about
- now
- apps
- books
- portfolio
- paintings
- uiux
- contact
- code
- taoism
- clear
- themes (default, snow, mist, zen, tao, mountain)
`;

export default helpOutput;