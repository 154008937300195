// src/outputs/about.js

const aboutOutput = `
  <p><strong>James Godwin</strong><br />
  <i>UX specialist | Product Designer</i></p>
  <p><strong>We take borrowed truths to become knowledgeable. But to be knowledgeable is not to know.</strong></p>
  <p>I studied both Mechanical Engineering and Graphic Design. Engineering has given me a solid foundation to build a career in technology. Art and design, being my passion, has enabled me to understand the user. I've married the two worlds in the businesses and products I've built over the years.</p>
  <p>INTP personality. Creative, multi-dimensional, energetic, considerate, curious, explorer, and adaptable. Like to deeply experience and explore life and inspire others to do the same. An abstract thinker, enjoy looking at the bigger picture. Enjoy learning and committed to acquiring knowledge and/or skills. I have an inventive mind that usually generates more possibilities than I can handle or fund. I believe in balance so my technical pursuits are balanced with creative outlets. Believe anything is possible :)</p>
  <p>Other passions, Tai Chi which I've been studying for over 25 years with <a href="https://dantian.co.za/dr-lin-feng-chao/" target="_blank">Grandmaster Dr. Lin Feng-Chao</a> student of the legendary <a href="https://dantian.co.za/cheng-man-ching/">Cheng Man-Ching</a>.</p>
  
  <p>Windsurfing & Kiteboarding for those days when you just have to hold on for the ride and also the cure for anything is saltwater!</p>
  <hr>
  <p class="commands-text">Available commands: - help - about - now - apps - books - portfolio - paintings  - uiux - contact - code - taoism<br />themes (default, snow, mist, zen, tao, mountain)  - clear</p>

 
`;

export default aboutOutput;