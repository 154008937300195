const booksOutput = `
<p class="app-item">
  <img src="the-rhyming-tao.webp" alt="The Rhyming Tao: A Poetic Journey Through the Tao Te Ching" class="app-icon">
  <span><strong>The Rhyming Tao: A Poetic Journey Through the Tao Te Ching</strong></span>
</p>
<p>This rhyming rendition of the Tao Te Ching invites readers on a lyrical journey through the realms of philosophy, spirituality. - <a href="https://www.amazon.com/dp/B0CPGKDF1P" target="_black">view on amazon</a></p>

<p class="app-item">
  <img src="the-silicon-sage.webp" alt="The Silicon Sage: AI Interpretations of the Tao Te Ching" class="app-icon">
  <span><strong>The Silicon Sage: AI Interpretations of the Tao Te Ching</strong></span>
</p>
<p>AI Interpretations of the Tao Te Ching," a groundbreaking exploration where the enduring wisdom of ancient Taoist philosophy intersects with the cutting-edge capabilities of artificial intelligence.  - <a href="https://www.amazon.com/dp/B0CQ3CYJ1V" target="_black">view on amazon</a></p>

  <p class="app-item">
  <img src="little-panda-learns-the-tao.webp" alt="Little Panda Learns the Tao" class="app-icon">
  <span><strong>Little Panda Learns the Tao</strong></span>
</p>
<p>Illustrated children's book that introduces young readers to the wisdom of Taoism through engaging rhyming stories. - <a href="https://www.amazon.com/dp/B0C46B54W7" target="_black">view on amazon</a></p>

<p class="app-item">
  <img src="embracing-lifes-journey.webp" alt="Embracing Life's Journey Your Guide to Personal Growth with the I Ching" class="app-icon">
  <span><strong>Embracing Life's Journey Your Guide to Personal Growth with the I Ching</strong></span>
</p>
<p>This innovative guide incorporates insights generated by advanced AI technology, offering a fresh, unique perspective on the I Ching's timeless wisdom. - <a href="https://www.amazon.com/dp/B0CB76665M" target="_black">view on amazon</a></p>

<p class="app-item">
  <img src="uncharted-waters.webp" alt="Uncharted Waters: The Silent Power of Choice" class="app-icon">
  <span><strong>Uncharted Waters: The Silent Power of Choice</strong></span>
</p>
<p>In the vast expanse of the ocean, a small boat named Hope embarks on a journey unlike any other. Facing calm seas and raging storms, Hope discovers a profound truth: the power of choice. Inspired by the timeless wisdom of Viktor E. Frankl. - <a href="https://www.amazon.com/dp/B0CHYXFLRY" target="_black">view on amazon</a></p>

<hr>
  <p class="commands-text">Available commands: - help - about - now - apps - books - portfolio - paintings  - uiux - contact - code - taoism<br />themes (default, snow, mist, zen, tao, mountain)  - clear</p>
 
`;
export default booksOutput;