const uiuxOutput = `
<p>As a <strong>UX Specialist and Product Designer</strong>, I focus on solving real problems through purposeful design. I strive to create experiences that are visually engaging and deeply meaningful.</p>

<strong>Design Sprints: Rapid Innovation</strong>  
<p>I use design sprints to quickly transform complex challenges into clear, actionable solutions. Through sketching, prototyping, and real user testing, I discover what works in days, not weeks.</p>

<strong>From Vision to Reality</strong>  
<p>Every solution starts with understanding the end goal, diverging in ideas, converging on the best options, and refining them through real-world feedback. I move swiftly from concept to tangible outcomes that resonate with users.</p>

<strong>Building with Confidence</strong>  
<p>With validated prototypes and genuine insights, I build with confidence, ensuring every product meets real needs and exceeds expectations.</p>

<strong>Design with Purpose</strong>  
<p>For me, great design is about more than aesthetics—it's about crafting experiences that connect, engage, and create lasting value.</p>

<hr>
  <p class="commands-text">Available commands: - help - about - now - apps - books - portfolio - paintings  - uiux - contact - code - taoism<br />themes (default, snow, mist, zen, tao, mountain)  - clear</p>
`;
export default uiuxOutput;